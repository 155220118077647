<template lang="pug">
v-card.testimonial(outlined v-on:click="url(testimonial.link)" height=240)
  v-card-title
    v-icon.mdi(medium left :class="testimonial.icon")
    span.text-subtitle-1.font-weight-bold {{ testimonial.source }}
  v-card-text.text-body-2.text--secondary
    .testimonial-desc(:class="testimonial.size") {{ testimonial.description }}
    .mt-4
      v-avatar.mr-2(size="32")
        v-img(:src="testimonial.avatar")
      span.text--secondary.text-overline {{ testimonial.name }}
</template>

<script>
export default {
  props: {
    testimonial: {
      type: Object,
      default() {
        return {
          id: Number,
          description: String,
          avatar: String,
          icon: String,
          name: String,
          source: String,
          link: String,
          size: String
        }
      }
    }
  },
  methods: {
    url(url) {
      const win = window.open(url, '_blank')
      win.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.testimonial {
  min-height: 150px;
  .testimonial-desc {
    height: 96px;
    overflow: hidden;
    max-width: 256px;
    &.l {
      max-width: 320px;
    }
    &.xl {
      max-width: 600px;
    }
  }
}
</style>