<template lang="pug">
span
  template(v-if="symbol === 'LaTeX'")
    | L
    span.a a
    .
      T
    span.e e
    .
      X
  template(v-else-if="symbol === 'TeX'")
    |  T
    span.e e
    .
      X
  template(v-else) {{ symbol }}
</template>

<script>
export default {
  props: {
    symbol: {
      type: String,
      default: 'LaTeX'
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  // font-family: 'CMU Serif', cmr10, LMRoman10-Regular, 'Latin Modern Math', 'Nimbus Roman No9 L', 'Times New Roman', Times, serif;
  .a {
    text-transform: uppercase;
    font-size: 0.75em;
    vertical-align: 0.25em;
    margin-left: -0.36em;
    margin-right: -0.15em;
    line-height: 1ex;
  }
  .e {
    text-transform: uppercase;
    vertical-align: -0.25em;
    margin-left: -0.1667em;
    margin-right: -0.125em;
    line-height: 1ex;
  }
}
</style>