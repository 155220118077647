
<template lang="pug">
  .carouselContainer
    .carousel(v-on:scroll.passive="handleScroll" :class="carouselClass" ref="carousel")
      .carouselItem
        slot(name="leading")
      .carouselItem(v-for="(item, index) in items")
        slot(name="content" :item="item" :index="index")
    .overflow.left.py-1(v-if="isScrolled" :class="{ shift }")
      v-btn(fab elevation=0 v-bind="btnAttrs" :width="chevronSize" :height="chevronSize" @click="prev")
        v-icon(:size="iconSize") mdi-chevron-left
    .overflow.right.py-1(v-if="!scrollLast" :class="{ shift }")
      v-btn(fab elevation=0 v-bind="btnAttrs" :width="chevronSize" :height="chevronSize" @click="next")
        v-icon(:size="iconSize") mdi-chevron-right
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    offset: {
      type: Number,
      default: 300
    },
    verticalPadding: {
      type: String,
      default: 'py-4'
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    shift: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isScrolled: false,
      scrollLast: false
    }
  },
  watch: {
    items(val) {
      this.$nextTick(() => {
        this.calculate(this.$refs.carousel)
      })
    }
  },
  mounted() {
    this.calculate(this.$refs.carousel)
  },
  computed: {
    chevronSize() {
      return this.large ? 32 : 20
    },
    iconSize() {
      return this.large ? 'x-large' : 'medium'
    },
    btnAttrs() {
      if (!this.$vuetify.theme.dark) {
        return { dark: true }
      }
      return { light: true }
    },
    carouselClass() {
      const alignCenter = this.alignCenter ? 'alignCenter' : ''
      return `${this.verticalPadding} ${alignCenter} ${this.mask}`
    },
    mask() {
      if (this.isScrolled && !this.scrollLast) {
        return 'bothMask'
      } else if (this.isScrolled) {
        return 'leftMask'
      } else if (!this.scrollLast) {
        return 'rightMask'
      }
      return ''
    }
  },
  methods: {
    handleScroll(ev) {
      this.calculate(ev.target)
    },
    prev() {
      const carousel = this.$refs.carousel
      if (carousel && carousel.scrollBy) {
        carousel.scrollBy({ left: (this.offset || 300) * -1, behavior: 'smooth' })
      }
    },
    next() {
      const carousel = this.$refs.carousel
      if (carousel && carousel.scrollBy) {
        carousel.scrollBy({ left: this.offset || 300, behavior: 'smooth' })
      }
    },
    calculate(target) {
      if (target) {
        this.isScrolled = target.scrollLeft > 8
        this.scrollLast = (target.scrollLeft + target.clientWidth + 16 >= target.scrollWidth || target.scrollWidth <= target.clientWidth)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carouselContainer {
  position: relative;
  .overflow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.shift {
      top: 35% !important;
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &.alignCenter {
      align-items: center;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .carouselItem {
      flex: 0 0 auto;
    }
    &.bothMask {
      mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 6%, rgba(0,0,0,1) 94%, rgba(0,0,0,0) 100%);
    }
    &.leftMask {
      mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 6%, rgba(0,0,0,1) 100%);
    }
    &.rightMask {
      mask-image: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 94%, rgba(0,0,0,0) 100%);
    }
  }
}
</style>