<template lang="pug">
  div
    v-card.d-flex.align-center.elevation-0.px-2(color="transparent" width="100%" min-height=400)
      v-card#headerContent.mx-auto.elevation-0.py-12.align-center(width="100%" max-width=1200 color="transparent")
        v-row.d-flex.align-center.mx-auto
          v-col.text-center.text-md-left(cols="12" md="5")
            h1.black--text Create Stunning Documents.
            h1.black--text.font-italic On The Go.
            .text-title.black--text.mt-4 Format your Markdown &amp; Fountain texts beautifully into APA, MLA paper, book, article, screenplay, mindmap, presentation slides and more.
            v-btn.text-capitalize.mt-4.mb-2(elevation="0" color="white" @click="goToEditor" light) Try JotterPad™ for free
          v-col(cols="12" md="7")
            v-img(src="/home/web_overview_devices2.png" contain width="100%")
              template(v-slot:placeholder)
                v-skeleton-loader(type="image@3" width="100%")
    v-card#download.elevation-0.d-flex.justify-center.my-2(color="transparent")
      .d-flex.justify-center.flex-wrap
        a(href="https://play.google.com/store/apps/details?id=com.jotterpad.x" target="_blank")
          img.mb-2.mr-2(src="/home/badge_android.svg")
        a(href="https://apps.apple.com/us/app/id1520190857" target="_blank")
          img.mb-2.mr-2(src="/home/badge_app_store.svg")
        a(href="https://apps.apple.com/us/app/id1520190857" target="_blank")
          img.mb-2.mr-2(src="/home/badge_mac_app_store.svg")
        a(href="https://chrome.google.com/webstore/detail/jotterpad-writer-screenpl/eedchpibggikoahhgjpikeacbnlnnfge/" target="_blank")
          img.mb-2.mr-2(src="/home/badge_chrome_webstore.svg")
    v-divider(light)
    #write.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Simple Text Formatting Via
        h2.px-12.black--text.mt-0 Lightweight Markup
        p.mt-4.mb-6.black--text Use lightweight markup such as #heading to add
          span(style="font-size:1.5rem;")  h1
          |  and **
          strong bold
          | ** to bold text. The syntax is consistent when working across Markdown, Fountain &amp;!{' ' }
          Latex
          |  documents, making it all the more easier.
        .d-flex.my-4.mx-auto.text-center.justify-center
          v-item-group(v-model="introIndex" mandatory)
            v-item(v-for="type in types" :key="type.id" v-slot="{ active, toggle }")
              v-btn.mr-1.text-capitalize.black--text.rounded-lg.elevation-0.mb-2(:color="active ? '#12a18d' : 'transparent'" @click="toggle" rounded light :disabled="type.isDisabled")
                template(v-if="type.id === 2")
                  Latex.mr-1
                  v-chip(x-small outlined disabled) BETA
                template(v-else) {{ type.title }}
        .introBackgroundWrapper
          v-img.introBackground(:src="introBackground" contain)
            template(v-slot:placeholder)
              v-skeleton-loader(type="image@3" width="100%")
          v-lazy(:options="{ threshold: 0.5 }")
            v-img.introScreenshot(:src="introScreenshot" :alt="pluginAlt")
              template(v-slot:placeholder)
                v-progress-circular.mx-auto(color="accent" indeterminate size="24")
    v-divider(light)
    #cloud.text-center.pt-8.pb-8(style="min-height:400px;")
      .bound
        p.hoefler-subtitle.black--text.mb-0 Seamless Intergration With
        h2.px-12.black--text.mt-0 Popular Cloud Services
        p.mt-4.mb-6.black--text Work directly with popular cloud services such as iCloud, Google Drive, Dropbox &amp; Microsoft OneDrive — without leaving JotterPad.
        v-card.mx-auto.pt-4.rounded-xl.elevation-3(max-width=380 width="100%" color="#f8f8f8")
          .py-8.d-flex.justify-space-around
            v-img(src="/home/cloud_google_drive.svg" contain max-width=72)
            v-img(src="/home/cloud_dropbox.svg" contain max-width=72)
          .py-8.d-flex.justify-space-around
            v-img(src="/home/cloud_onedrive.svg" contain max-width=88)
            v-img(src="/home/cloud_icloud.png" contain max-width=90)
    v-divider(light)
    #plugins.text-center.py-8.px-3
      .bound
        p.hoefler-subtitle.black--text.mb-0 Get Endless Possibilities With
        h2.px-12.black--text.mt-0 Powerful Integrated Plugins
        p.mt-4.mb-6.black--text JotterPad supports extensive plugins ranging from mind maps, to flow charts, musical notation, chemistry equations and more.
        a(href="/app/plugins")
          v-btn(text light)
            v-icon.mr-2 mdi-puzzle
            | Browse All Plugins
      .bound2.mx-auto.pt-0.pt-md-12
        v-row
          v-col(cols="12" md="3")
            v-item-group(v-if="$vuetify.breakpoint.mdAndUp" v-model="pluginIndex" mandatory)
              v-item(v-slot="{ active, toggle }" v-for="plugin in plugins" :key="plugin.id")
                v-btn.mb-4.text-capitalize.black--text.rounded-lg.elevation-0(:color="active ? '#12a18d' : 'transparent'" @click="toggle" rounded light)
                  v-avatar.rounded.mr-2(size="24" :color="plugin.background")
                    v-icon(small :color="plugin.color") {{ plugin.icon }}
                  | {{ plugin.title }}
          v-col(cols="12" md="9")
            .pluginBackgroundWrapper
              v-img.pluginBackground(:src="pluginBackground" contain)
                template(v-slot:placeholder)
                  v-skeleton-loader(type="image@3" width="100%")
              v-lazy(:options="{ threshold: 0.5 }")
                v-img.pluginScreenshot(:src="pluginScreenshot" :alt="pluginAlt" contain)
                  template(v-slot:placeholder)
                    v-progress-circular.mx-auto(color="accent" indeterminate size="24")
        v-horizontal-scroll.mt-4(v-if="$vuetify.breakpoint.smAndDown" :items="plugins" :large="false" style="width: 100%;")
          template(v-slot:content="{item, index}")
            v-btn.mr-1.text-capitalize.black--text.rounded-lg.elevation-0(:color="pluginIndex === index ? '#12a18d' : 'transparent'" @click="pluginIndex = index" rounded light)
              v-avatar.rounded.mr-2(size="24" :color="item.background")
                v-icon(small :color="item.color") {{ item.icon }}
              | {{ item.title }}
    v-divider(light)
    #citation.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Organize Citations Efficiently With
        h2.px-12.black--text.mt-0 Bibliography Management With BibTex
        p.mt-4.mb-6.black--text Add citations by uploading Bib files, or copying and pasting BibTex into Markdown documents. Further manage and edit your citations using the BibTex plugin, and print your references along with popular citation formats such as APA, MLA, Chicago, and AMA thereafter with ease.
        a(href="/app/plugins/bibtex-citation-management")
          v-btn(text light)
            v-icon.mr-2 mdi-bookshelf
            | Explore BibTex Plugin
        v-img.mt-4.mt-md-12(src="/home/jotterpad-citation.webp" contain)
        p.mt-md-12.mt-4.black--text Procure BibTex from publishing platforms such as Springer, Research Gate, JSTOR, Google Scholar, Wiley, and Google books to expedite adding of citations for you.
        .d-flex.justify-space-around.flex-wrap
          div
            v-img.mr-2.mt-4(src="/home/citation-springer-logo.svg" contain height=64 width=96 alt="Springer logo")
          div
            v-img.mr-2.mt-4(src="/home/citation-wiley-logo-white.png" contain height=64 width=96 alt="Wiley logo")
          div
            v-img.mr-2.mt-4(src="/home/citation-JSTOR-logo.svg" contain height=64 width=64 alt="JSTOR logo")
          div
            v-img.mr-2.mt-4(src="/home/citation-research-gate-logo.png" contain height=64 width=96 alt="Researchgate logo")
          div
            v-img.mt-4(src="/home/citation-google-scholar-logo.png" contain height=64 width=96 alt="Google Scholar logo")
    v-divider(light)
    #wizard-ai.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Brainstorm &amp; Generate Content With
        h2.px-12.black--text.mt-0 Wizard A.I.
        p.mt-4.mb-6.black--text Generate writing suggestions and prompts using Wizard A.I. powered by GPT-3 technology. Input a question or topic that you are brainstorming and have the answers generated for you within seconds. Use the functions of this plugin as a bedrock for your next writing endeavor.
      .bound2.mx-auto.pt-0.pt-md-12.px-4
        v-row
          v-col(cols="12" md="4")
            v-item-group(v-if="$vuetify.breakpoint.mdAndUp" v-model="aiIndex" mandatory)
              v-item(v-slot="{ active, toggle }" v-for="aiExample in aiExamples")
                v-btn.mb-4.text-capitalize.black--text.rounded-lg.elevation-0(:color="active ? '#12a18d' : 'transparent'" @click="toggle" min-width="60%" rounded light)
                  v-icon.mr-2(small) {{ aiExample.icon }}
                  | {{ aiExample.title }}
          v-col(cols="12" md="8")
            client-only
              v-lazy(:options="{ threshold: 0.5 }")
                v-card.mx-auto.rounded-lg(light width="100%")
                  v-card-title
                    h3.pa-0.text-left(style="line-height:1.5rem; word-break: break-word;") {{ aiExamples[aiIndex].q }}
                  v-card-text.pt-2.pb-12.d-flex
                    template(v-if="aiExamples[aiIndex].p")
                      v-card.text-left.input.pa-4(height="240" width="100%" outlined style="position: relative;")
                        div(style="white-space: pre-wrap;") {{ aiExamples[aiIndex].p }}
                      v-icon mdi-arrow-right
                    v-card.text-left.output.pa-4(height="240" width="100%" outlined style="position: relative;")
                      div
                        vue-typer(:text="aiExamples[aiIndex].a" erase-style="clear" :type-delay="15" @typed-char="onTypewriterTypedChar" @completed="onTypewriterComplete")
                    .thinking
                        .text-center.mb-3(v-if="isTyping")
                          v-chip.black--text(color="#ad89f7" small)
                            .blink
                              v-icon.mr-2(small) mdi-auto-fix
                              span.text-caption.font-weight-bold Wizard is typing...
                        .text-center.mb-3(v-else)
                          v-chip.black--text(color="#ad89f7" small)
                            v-icon.mr-2(small) mdi-check
                            span.text-caption.font-weight-bold Wizard is done.
        v-horizontal-scroll.mt-4(v-if="$vuetify.breakpoint.smAndDown" :items="aiExamples" :large="false" style="width: 100%;")
          template(v-slot:content="{item, index}")
            v-btn.mr-1.text-capitalize.black--text.rounded-lg.elevation-0(:color="aiIndex === index ? '#12a18d' : 'transparent'" @click="aiIndex = index" rounded light)
              v-icon.mr-2(small) {{ item.icon }}
              | {{ item.title }}
    v-divider(light)
    #images.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 New Way To
        h2.px-12.black--text.mt-0 Embed Images to Markdown Files
        p.mt-4.mb-6.black--text JotterPad uploads your image to the cloud &amp; creates a unique random link when you embed an image into a Markdown file. The embedded images can be displayed on any device.
        .imagesBackgroundWrapper
          v-img.imagesBackground(src="/home/jotterpad-editor-bg.png" contain)
            template(v-slot:placeholder)
              v-skeleton-loader(type="image@3" width="100%")
          v-lazy(:options="{ threshold: 1 }")
            img.imagesScreenshot(src="/home/jotterpad-image-from-gallery.webp")
    v-divider(light)
    #images.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Get High Quality
        h2.px-12.black--text.mt-0 Images From Unsplash
        p.mt-4.mb-6.black--text Spruce up your work by adding images via Unsplash. Let images of your choice speak a thousand words along with your writing.
        .imagesBackgroundWrapper
          v-img.imagesBackground(src="/home/jotterpad-editor-dark-bg.png" contain)
            template(v-slot:placeholder)
              v-skeleton-loader(type="image@3" width="100%")
          v-lazy(:options="{ threshold: 0.5 }")
            img.imagesScreenshot(src="/home/jotterpad-unsplash.webp")
    v-divider(light)
    #research.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Search Up Words Via
        h2.px-12.black--text.mt-0 Quick Research
        p.mt-4.mb-6.black--text Search up word definitions, synonyms and antonyms with JotterPad's built-in Dictionary and Thesaurus. Let the Rhyming Dictionary help you find the extra rolling cadence in your words.
        .researchBackgroundWrapper
          v-img.researchBackground(src="/home/jotterpad-editor-bg.png" contain)
            template(v-slot:placeholder)
              v-skeleton-loader(type="image@3" width="100%")
          v-lazy(:options="{ threshold: 0.5 }")
            img.researchScreenshot(src="/home/jotterpad-research.webp")
          v-lazy(:options="{ threshold: 0.5 }")
            img.researchDialogScreenshot(src="/home/jotterpad-research-dialog.webp")
    v-divider(light)
    #blogs.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Publish To Blogs
        h2.px-12.black--text.mt-0 Ghost, Wordpress &amp; Tumblr
        p.mt-4.mb-6.black--text Instantly publish your works to a blogging platform of your choosing. JotterPad lets you automatically upload whatever you’ve written to Ghost, Wordpress and Tumblr as drafts or published posts.
        .blogsBackgroundWrapper
          v-img.blogsBackground(src="/home/jotterpad-editor-bg.png" contain)
            template(v-slot:placeholder)
              v-skeleton-loader(type="image@3" width="100%")
          v-lazy(:options="{ threshold: 0.5 }")
            img.blogsScreenshot(src="/home/jotterpad-blogs.webp")
    v-divider(light)
    #export.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Export To Multiple Formats
        h2.px-12.black--text.mt-0 DOCX, PDF &amp; FDX
        p.mt-4.mb-6.black--text
          | JotterPad converts Markdown, Fountain &amp;!{' '}
          Latex
          |  into rich, formatted text and seamlessly exports it to Word, PDF, and Final Draft thereafter with zero fuss.
        .exportBackgroundWrapper
          v-img.exportBackground(src="/home/jotterpad-editor-bg.png" contain)
            template(v-slot:placeholder)
              v-skeleton-loader(type="image@3" width="100%")
          v-lazy(:options="{ threshold: 0.5 }")
            img.exportScreenshot(src="/home/jotterpad-export.webp")
          v-lazy(:options="{ threshold: 0.5 }")
            img.exportDialogScreenshot(src="/home/jotterpad-export-dialog.webp")
    v-divider(light)
    #epub.text-center.pt-8.pb-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Create Formatted eBooks With
        h2.px-12.black--text.mt-0 ePub
        p.mt-4.mb-6.black--text Convert your markdown documents into formatted eBooks with ease. Export and view your work on platforms such as Apple Books, Nook, Kobo, and Amazon Kindle with standard electronic publishing formatting in place for you.
        v-card.rounded-xl.elevation-0.mt-4.mb-6(color="#262626")
          v-card.py-4.px-6.px-md-8.rounded-lg.elevation-0(color="transparent")
            img.books(:src="epubImage")
        .d-flex.my-4.mx-auto.text-center.justify-center
          v-item-group(v-model="epubIndex" mandatory)
            v-item(v-for="epubReader in epubReaders" :key="epubReader.name" v-slot="{ active, toggle }")
              v-btn.mr-1.text-capitalize.black--text.rounded-lg.elevation-0.mb-2(:color="active ? '#12a18d' : 'transparent'" @click="toggle" rounded light)
                v-avatar.rounded.mr-2(size="22")
                    img(:src="epubReader.image")
                | {{ epubReader.name }}
    v-divider(light)
    #templates.text-center.pt-8
      .bound
        p.hoefler-subtitle.black--text.mb-0 Discover &amp; Browse
        h2.px-12.black--text.mt-0 100+ Beautifully-Designed Templates
        p.mt-4.mb-6.black--text
          | With JotterPad's extensive range of templates, formatting requirements never have to be intimidating again. From novel, to screenplay, article, report, letter, and even presentation slides, you can now create stunning documents with PDF in less than a minute using Markdown, Fountain &amp;!{' ' }
          Latex
          |  files.
        a(href="/app/templates")
          v-btn(text light)
            v-icon.mr-2 mdi-view-grid
            | Browse All Templates
      .scrollingWrapper.px-10.pt-4.pb-8(style="width:100%;")
        v-card.my-4.elevation-0.mx-6.template(width="260" color="transparent" light v-for="template in templates")
          v-hover(v-slot="{ hover }")
            v-card.rounded-0.animatedTemplateCard(:elevation="hover ? 16 : 1" :color="template.color" height="360" width="260")
              NuxtLink(:to="template.link")
                v-img(:src="template.img" height="360" width="260" color="white" :contain=template.isContain position="center center" :alt="template.alt")
                  template(v-slot:placeholder)
                    v-skeleton-loader(type="image@3" height="360" width="260")
          .mt-4.text-left
            .text-subtitle-2.white--text {{ template.description }}
            NuxtLink(:to="template.createLink")
              v-hover(v-slot="{ hover }")
                .mt-2
                  v-icon(v-if="hover" color="pink darken-2")  mdi-cards-heart
                  v-icon(v-else dark style="opacity:40%;") mdi-cards-heart-outline
    #featuredAuthor.pt-8
      v-row.bound
        v-col.mb-0(cols="12" md="6" style="min-height: 400px;")
          v-card(outlined height="100%")
            v-img.align-end(:src="images.featuredAuthor" height="250px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)")
              h2.v-card-title.pa-4 Featured Author
            v-card-subtitle.white--text Join us as we interview Ian Williams, a screenplay writer who made it to the quarter finals of 2020's Austin Film Festival.
            v-card-actions
              v-btn(text href="https://blog.jotterpad.app/ian-williams-interview/" target="_blank") Learn More
                v-icon mdi-chevron-right
        v-col(cols="12" md="6" style="min-height: 400px;")
          v-card(outlined height="100%")
            v-img.align-end(:src="images.featuredAuthor2" height="250px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)")
              h2.v-card-title.pa-4 Dare To Imagine
            v-card-subtitle.white--text Find out how JotterPad helps this role-playing game designer bring to life imaginative and intriguing games.
            v-card-actions
              v-btn(text href="https://blog.jotterpad.app/interview-with-game-designer-cezar-capacle-narrative-driven-game-enthusiast/" target="_blank") Learn More
                v-icon mdi-chevron-right
      v-row.bound
        v-col.mb-0(cols="12")
          v-card(outlined)
            v-img.align-end(:src="images.blogImage" height="250px" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)")
              h2.v-card-title.pa-4 Get Started
            v-card-subtitle.white--text Simplify your writing process. Make it seamless with Markdown and Fountain format.
            v-card-actions
              v-btn(text href="https://blog.jotterpad.app/why-need-wysiwyg-markdown-fountain-editor/" target="_blank") Learn More
                v-icon mdi-chevron-right
    #testimonial.pb-2
      v-sheet.mx-auto.bound(color="#211f21" elevation="0")
        v-horizontal-scroll(:items="testimonials" :large="false")
          template(v-slot:content="{item}")
            testimonial-card.mx-3(:testimonial="item" transparent)
</template>

<script>
import TestimonialCard from '../components/home/TestimonialCard.vue'
import Latex from '../components/Latex.vue'
import VHorizontalScroll from '../components/VHorizontalScroll'

export default {
  layout: 'external',
  components: {
    VHorizontalScroll,
    TestimonialCard,
    Latex
  },
  data() {
    return {
      introIndex: 0,
      pluginIndex: 0,
      epubIndex: 0,
      aiIndex: 0,
      isTyping: true,
      images: {
        featuredAuthor: '/home/featured_author_ianwilliams.jpg',
        featuredAuthor2: '/home/featured_author_scraps.jpg',
        blogImage: '/home/wysiwyg.png'
      },
      types: [
        {
          id: 0,
          title: 'Markdown',
          isDisabled: false
        },
        {
          id: 1,
          title: 'Fountain',
          isDisabled: false
        },
        {
          id: 2,
          title: 'LaTeX',
          isDisabled: true
        }
      ],
      plugins: [
        {
          id: 0,
          title: 'Markmap Mindmap',
          icon: 'mdi-graph',
          background: '#be69fa',
          color: '#5f2e82',
          img: '/home/plugins-markmap-mind-map2.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 1,
          title: 'ABC Notation',
          icon: 'mdi-music-note',
          background: '#eeeeee',
          color: 'black',
          img: '/home/plugins-abc-notation-musical.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 2,
          title: 'ChordPro',
          icon: 'mdi-guitar-acoustic',
          background: '#ef7024',
          color: '#6f061d',
          img: '/home/plugins-chord-pro-lyrics.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 3,
          title: 'TeX Equation',
          icon: 'mdi-function-variant',
          background: '#278c1c',
          color: 'white',
          img: '/home/plugins-tex-eqn.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 4,
          title: 'mhChem Equation',
          icon: 'mdi-molecule-co2',
          background: '#faf6bc',
          color: '#5d5809',
          img: '/home/plugins-mhchem.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 5,
          title: 'SMILES Notation',
          icon: 'mdi-molecule',
          background: '#ffc375',
          color: '#331e02',
          img: '/home/plugins-smiles.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 6,
          title: 'Mermaid Diagram',
          icon: 'mdi-chart-pie',
          background: '#643ee3',
          color: 'white',
          img: '/home/plugins-mermaid.webp',
          alt: '',
          type: 'markdown'
        },
        {
          id: 7,
          title: 'Fountain Scripts',
          icon: 'mdi-drama-masks',
          background: '#00ccff',
          color: '#00546a',
          img: '/home/plugins-fountain.webp',
          alt: '',
          type: 'fountain'
        },
        {
          id: 8,
          title: 'Screenplay prompts',
          icon: 'mdi-lightbulb',
          background: '#fff600',
          color: 'black',
          img: '/home/plugins-screenplay-prompts.webp',
          alt: '',
          type: 'fountain'
        }
      ],
      templates: [
        {
          id: 0,
          img: '/home/template_apa.png',
          alt: 'American Psychological Association (APA) with title page, headings, citations, and more according to the APA 7th Edition manual. ',
          description: 'Format your title page, headings, citations, and more according to the 7th Edition of the American Psychological Association (APA) with this template, and have academic papers ready in no time.',
          link: '/app/templates/apa-7th-edition-american-psychological-association-md',
          createLink: '/app/templates/apa-7th-edition-american-psychological-association-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 1,
          img: '/home/template_mla.png',
          alt: 'Modern Language Association (MLA) with titles, headers, citations, footnotes, and more formatted to the 9th edition of the MLA guide.',
          description: 'Use this template to keep Modern Language Association (MLA) formatting in check, with titles, headers, citations, footnotes, and more formatted to the 9th edition of the MLA guide.',
          link: '/app/templates/mla-9th-edition-modern-language-association-md',
          createLink: '/app/templates/mla-9th-edition-modern-language-association-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 2,
          img: '/home/template_novel.jpg',
          alt: 'Novel Book with the chapter number, chapter heading, and prose that begins with a red drop caps decorative element.',
          description: 'The Novel Book with Drop Caps template embellishes your writing with a decorative drop caps element and takes care of headings, justification, line-spacing, and many more for you.',
          link: '/app/templates/novel-book-md',
          createLink: '/app/templates/novel-book-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 3,
          img: '/home/template_programming_assignment.png',
          alt: 'Programming document which colorizes language specific keywords, operators, and elements in your code with various languages such as Python, C#, HTML, JavaScript, and more.',
          description: 'Produce a programming document that colorizes language specific keywords, operators, and elements in your code with various languages such as Python, C#, HTML, JavaScript, and more.',
          link: '/app/templates/programming-assignment-md',
          createLink: '/app/templates/programming-assignment-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 4,
          img: '/home/template_screenplay.jpg',
          alt: 'Screenplay template that includes scene number, action line, character names, dialogues, and other industry standard elements.',
          description: 'Use this Screenplay template to format your manuscript\'s line-spacing, page margins, headings and other formatting requirements for you to produce a professional standard screenplay.',
          link: '/app/templates/screenplay-fountain',
          createLink: '/app/templates/screenplay-fountain',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 5,
          img: '/home/template_essay.jpg',
          alt: 'A Simple Narrative Essay Template that provides the essay title, name of author, specified line margins and justification.',
          description: 'Use this Simple Narrative Essay to format your essay\'s headers, titles, page margins, and sub-sections for your assignments and writing endeavors, and produce quality work.',
          link: '/app/templates/simple-narrative-essay-sanserif-md',
          createLink: '/app/templates/simple-narrative-essay-sanserif-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 6,
          img: '/home/template_lab_report.jpg',
          alt: 'A structured lab report template with the title, discrete sections for abstract, introduction, theoretical framework, and conclusion.',
          description: 'Produce an exemplary lab report with an abstract, headings, introduction, results, references amongst others, with the help of Lab Report template and its formatting guidelines.',
          link: '/app/templates/lab-report-md',
          createLink: '/app/templates/lab-report-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 7,
          img: '/home/template_business_plan.png',
          alt: 'The business plan template has a title, short description, a spreadsheet template for financial planning, and sections for business development processes.',
          description: 'Effortlessly craft a Business plan that includes a company overview, business idea, and a financial plan with this template and embark on your next business venture.',
          link: '/app/templates/business-plan-md',
          createLink: '/app/templates/business-plan-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 8,
          img: '/home/template_letter.jpg',
          alt: 'A drafted job application with a cover letter that has the recipient and sender addresses, opening and closing salutations, and enclosed attachments.',
          description: 'This Cover Letter template helps you include your salutations, recipient and sender information, necessary enclosures, etc. effortlessly, and creates a professional letter for your use.',
          link: '/app/templates/job-application-cover-letter-md',
          createLink: '/app/templates/job-application-cover-letter-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 9,
          img: '/home/template_meeting_minutes.png',
          alt: 'Meeting minutes templates with the date and time of a meeting, an "item" and "action" table, and a section to record the names of attendees.',
          description: 'Use this template to take down meeting minutes and organize them into a structured and organized table on the spot, and leave meetings with clear notes hereafter.',
          link: '/app/templates/meeting-minutes-with-table-md',
          createLink: '/app/templates/meeting-minutes-with-table-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 10,
          img: '/home/template_sheet_music.png',
          alt: 'Music sheet to compose musical notation, with elements like the treble clef, bar lines, musical notes, key or time signatures already formatted for you.',
          description: 'Use this template to compose your own music or create musical notation, with elements like the treble clef, bar lines, musical notes, key or time signatures already formatted for you.',
          link: '/app/templates/sheet-music-for-beginners-md',
          createLink: '/app/templates/sheet-music-for-beginners-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 11,
          img: '/home/template_textbook.jpg',
          alt: 'A readable and well-formatted textbook the chapter number, chapter heading, sub-heading, and justified text that follows after.',
          description: 'This Simple Textbook template can consolidate a cover page, contents page, format the content that follows after, etc. to produce a quality and readable textbook for your use.',
          link: '/app/templates/text-book-md',
          createLink: '/app/templates/text-book-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 12,
          alt: 'Autobiography book that includes cover, contents page, titling, numbering, text style and others.',
          img: '/home/autobiography-book.jpg',
          description: 'Let your readers dive into your life story by compiling the book cover, contents page, titling, numbering, text style and others easily into an autobiography with this template.',
          link: '/app/templates/autobiography-book-md',
          createLink: '/app/templates/autobiography-book-md',
          color: '#929292',
          isContain: 'true'
        },
        {
          id: 13,
          alt: 'Generate a Gantt chart using Mermaid and iron out budget allocation, roles and responsibilities and other details with this template.',
          img: '/home/template_gantt_chart_project.png',
          description: 'Execute a smooth-sailing project by generating a Gantt chart using Mermaid shorthand and ironing out budget allocation, roles and responsibilities and other details with this template.',
          link: '/app/templates/project-plan-md',
          createLink: '/app/templates/project-plan-md',
          color: 'white',
          isContain: 'false'
        },
        {
          id: 14,
          alt: 'A sample Employment Agreement template with a title and company name, and pre-determined sections for various business clauses and terms of employment.',
          img: '/home/template_employment_contract.jpg',
          description: 'This template guides you with drafting key contract sections such the scope of employment, monetary compensation, insurance coverage, and other terms of employment.',
          link: '/app/templates/employment-contract-executive-md',
          createLink: '/app/templates/employment-contract-executive-md',
          color: 'white',
          isContain: 'false'
        }
      ],
      testimonials: [
        {
          id: 0,
          description: 'Jotterpad app has been the best investment for my screenwriting needs. I\'m just finishing up a screenplay that I\'m working on. Thanks for all of your help!',
          avatar: '/home/twitter_jon_logan.jpg',
          icon: 'mdi-twitter',
          name: 'Jon Logan',
          source: 'Twitter',
          link: 'https://twitter.com/jonathanr_logan/status/1269009796163866624',
          size: 's'
        },
        {
          id: 1,
          description: 'If you\'re an Android user, @2appstudio makes Jotterpad which works on both Android and Chromebooks.  Also offers some cloud integration for backing up notes/syncing between devices.',
          avatar: '/home/twitter_iamcj.jpg',
          icon: 'mdi-twitter',
          name: 'ForIamCJ',
          source: 'Twitter',
          link: 'https://twitter.com/ForIamCJ/status/1247961044561080321',
          size: 'l'
        },
        {
          id: 2,
          description: 'It\'s very nice app. We can write whatever we want and has no limit. Suppose we are writing a book, the title appears first, which is the thing I like very much. This is the best app for writing books and stories. I recommend you to download it. 😍',
          avatar: '/home/gplay_driishti_rathod.png',
          icon: 'mdi-google-play',
          name: 'Driishti Rathod',
          source: 'Google Play',
          link: 'https://play.google.com/store/apps/details?id=com.jotterpad.x&reviewId=gp%3AAOqpTOGxzeXOo-53PPyuw4kkZ0WydmOEyh8KO_ddwpbiuaW4MtJaYCoaBHUZgXUHhJLsSzmM8PWlblRIzt8PskY',
          size: 'l'
        },
        {
          id: 3,
          description: 'This little app is really helping me with writing. I want to write both novels and scripts for stage plays and/or movies. Either way, a story needs to be told. Ive been struggling with writer\'s block for years...all my best ideas come to me randomly in moments of euphoria when I am busy doing something else. When I\'d sit down to write though, I forgot everything and felt lost. This app helps me be sponteneous. Whenever my ideas come now this app on my phone helps me jot them. Thanks so much!!!',
          avatar: '/home/gplay_jessica.png',
          icon: 'mdi-google-play',
          name: 'Jessica Hill',
          source: 'Google Play',
          link: 'https://play.google.com/store/apps/details?id=com.jotterpad.x&reviewId=gp%3AAOqpTOH2UdPvXiXSS7dDgxTyxsqi063hg7ai_84k9R2kQKVscwAyVMP6aIHqMRP2KAPWxr7ugMkcnw5PjAdt5Rk',
          size: 'xl'
        }
      ],
      epubReaders: [
        { name: 'Apple Books', image: '/home/apple-books-logo.png' },
        { name: 'Kindle', image: '/home/kindle-logo.webp' },
        { name: 'Nook', image: '/home/nook-logo.png' },
        { name: 'Kobo', image: '/home/kobo-logo.webp' }
      ],
      aiExamples: [
        {
          id: 0,
          title: 'Brainstorm an outline of...',
          icon: 'mdi-format-header-pound',
          q: '🧠 Brainstorm an outline of biography of Steve Jobs',
          a: `# Introduction
## Early Life
## Education
## Career

# Early Life
## Family Background
## Childhood
## Early Interests

# Education
## High School
## College

# Career
## Apple
## NeXT
## Pixar
## Return to Apple

# Legacy
## Impact on Technology
## Impact on Business
## Impact on Society`
        },
        {
          id: 1,
          title: 'Write an introduction...',
          icon: 'mdi-human-greeting',
          q: '👩‍💻 Write an introduction about myself as a marketing executive.',
          p: '',
          a: `Hello everyone! My name is [Name] and I am a marketing executive with a passion for helping businesses reach their goals. I have been in the marketing industry for over 10 years and have worked with a variety of companies, from startups to Fortune 500s. I have a strong background in digital marketing, content marketing, and brand strategy. I am always looking for new and innovative ways to help businesses grow and succeed. I'm excited to be here and look forward to working with you all.`
        },
        {
          id: 1,
          title: 'Brainstorm 5 key points...',
          icon: 'mdi-format-list-text',
          q: '🌍🔥 Brainstorm 5 key points about global warming',
          p: '',
          a: `1. Global warming is the long-term warming of the planet due to increased levels of greenhouse gases in the atmosphere.
2. Global warming is causing the average global temperature to rise, leading to more extreme weather events such as heat waves, droughts, and floods.
3. The melting of polar ice caps and glaciers is causing sea levels to rise, leading to coastal flooding and displacement of coastal communities.
4. Global warming is causing changes in ecosystems, leading to species extinction and disruption of food webs.
5. Reducing emissions of greenhouse gases is essential to mitigate the effects of global warming.`
        },
        {
          id: 2,
          title: 'Summarize a paragraph for me',
          icon: 'mdi-content-cut',
          q: '✂️ Summarize',
          p: `The term derivative refers to a type of financial contract whose value is dependent on an underlying asset, group of assets, or benchmark. A derivative is set between two or more parties that can trade on an exchange or over-the-counter (OTC).

These contracts can be used to trade any number of assets and carry their own risks. Prices for derivatives derive from fluctuations in the underlying asset. These financial securities are commonly used to access certain markets and may be traded to hedge against risk. Derivatives can be used to either mitigate risk (hedging) or assume risk with the expectation of commensurate reward (speculation). Derivatives can move risk (and the accompanying rewards) from the risk-averse to the risk seekers.`,
          a: `Derivatives are financial contracts that derive their value from an underlying asset, group of assets, or benchmark, and can be used to either mitigate risk or take on risk in pursuit of reward.`
        },
        {
          id: 3,
          title: 'Simplify my content',
          icon: 'mdi-shape',
          q: '🔷🔴🟧 Simplify',
          p: `The term derivative refers to a type of financial contract whose value is dependent on an underlying asset, group of assets, or benchmark. A derivative is set between two or more parties that can trade on an exchange or over-the-counter (OTC).

These contracts can be used to trade any number of assets and carry their own risks. Prices for derivatives derive from fluctuations in the underlying asset. These financial securities are commonly used to access certain markets and may be traded to hedge against risk. Derivatives can be used to either mitigate risk (hedging) or assume risk with the expectation of commensurate reward (speculation). Derivatives can move risk (and the accompanying rewards) from the risk-averse to the risk seekers.`,
          a: `A derivative is a type of financial contract used to trade assets and manage risk. They are traded on exchanges or OTC and their prices are based on the underlying asset. They can be used to hedge against risk or speculate with the expectation of reward.`
        },
        {
          id: 4,
          title: 'Paraphrase your sentence',
          icon: 'mdi-shuffle-variant',
          q: '🔀 Paraphrase',
          p: `The term derivative refers to a type of financial contract whose value is dependent on an underlying asset, group of assets, or benchmark. A derivative is set between two or more parties that can trade on an exchange or over-the-counter (OTC).

These contracts can be used to trade any number of assets and carry their own risks. Prices for derivatives derive from fluctuations in the underlying asset. These financial securities are commonly used to access certain markets and may be traded to hedge against risk. Derivatives can be used to either mitigate risk (hedging) or assume risk with the expectation of commensurate reward (speculation). Derivatives can move risk (and the accompanying rewards) from the risk-averse to the risk seekers.`,
          a: `The term derivative refers to a type of financial contract whose worth is determined by an underlying asset, group of assets, or benchmark. These agreements are made between two or more parties who can trade on an exchange or over-the-counter (OTC). The prices of derivatives are based on changes in the underlying asset. These financial instruments are often used to gain access to certain markets and may be traded to protect against risk. Derivatives can be used to either reduce risk (hedging) or take on risk with the expectation of a corresponding reward (speculation). They can also be used to move risk (and the related rewards) from those who are risk-averse to those who are risk-seeking.`
        }
      ]
    }
  },
  watch: {
    aiIndex() {
      this.isTyping = true
    }
  },
  computed: {
    phoneheight() {
      if (this.$vuetify.breakpoint.xlOnly) {
        return 400
      } else if (this.$vuetify.breakpoint.smAndUp) {
        return 320
      } else {
        return 300
      }
    },
    pluginScreenshot() {
      return this.plugins[this.pluginIndex].img || ''
    },
    pluginAlt() {
      return this.plugins[this.pluginIndex].alt || ''
    },
    pluginBackground() {
      switch (this.plugins[this.pluginIndex].type || '') {
        case 'fountain':
          return '/home/jotterpad-fountain-editor-bg.png'
        default:
          return '/home/jotterpad-editor-bg.png'
      }
    },
    introBackground() {
      switch (this.introIndex) {
        case 1:
          return '/home/jotterpad-fountain-editor-bg.png'
        default:
          return '/home/jotterpad-editor-bg.png'
      }
    },
    introScreenshot() {
      switch (this.introIndex) {
        case 1:
          return '/home/fountain-content.webp'
        case 2:
          return '/home/markdown-content.webp'
        default:
          return '/home/markdown-content.webp'
      }
    },
    epubImage() {
      switch (this.epubIndex) {
        default:
        case 0:
          return '/home/epub-apple-books-preview.webp'
        case 1:
          return '/home/epub-kindle-preview.webp'
        case 2:
          return '/home/epub-kobo-preview.webp'
        case 3:
          return '/home/epub-nook-preview.webp'
      }
    }
  },
  mounted() {
    this.$vuetify.theme.dark = true
  },
  methods: {
    goToEditor() {
      this.$router.push('/app/editor/local?getstarted=true&new=markdown')
    },
    onTypewriterComplete() {
      this.isTyping = false
    },
    onTypewriterTypedChar() {
      const output = document.querySelector('.output')
      if (output) {
        output.scrollTop = output.scrollHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#headerContent {
  background-color: #13c4ac;
  background-repeat: repeat;
  padding: 0 16px;
}

#download {
  background-color: #13c4ac;
  padding: 0 16px;

  & img {
    height: 50px;
  }
}

h1, h2, h3 {
  font-family: 'Ideal Sans A', 'Ideal Sans B', sans-serif !important;
  font-weight: bold !important;
  line-height: 2.5rem;
}

.hoefler-subtitle {
  font-family: 'Ideal Sans A', 'Ideal Sans B', sans-serif !important;
  font-weight: light !important;
  font-size: 20px;
  font-style: italic;
}

.fade-out {
  -webkit-mask-image: -webkit-gradient(linear, left 80%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.theme--dark.v-stepper {
  background-color: #121212 !important;
}

.v-timeline-item__body {
  text-align: right !important;
}

#testimonial, #featuredAuthor {
  background: #211f21;
}

.scrollingWrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.bound {
  max-width: 800px;
  margin: auto;
  padding: 0 16px;
}

.bound2 {
  max-width: 1200px;
  margin: auto;
}

.introBackgroundWrapper {
  position: relative;
  .introBackground {
    position: relative;
  }
  .introScreenshot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.imagesBackgroundWrapper {
  position: relative;
  .imagesBackground {
    position: relative;
  }
  .imagesScreenshot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.blogsBackgroundWrapper {
  position: relative;
  .blogsBackground {
    position: relative;
  }
  .blogsScreenshot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.pluginBackgroundWrapper {
  position: relative;
  .pluginBackground {
    position: relative;
  }
  .pluginScreenshot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.exportBackgroundWrapper {
  position: relative;
  margin-top: 45px;
  margin-bottom: 40px;

  .exportBackground {
    position: relative;
  }
  .exportScreenshot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .exportDialogScreenshot {
    position: absolute;
    top: -30px;
    left: 25%;
    width: 50%;
  }
}

.books {
  width: 100%;
  -webkit-user-drag: none;
  user-drag: none;
}

.researchBackgroundWrapper {
  position: relative;
  margin-top: 80px;
  margin-bottom: 80px;

  .researchBackground {
    position: relative;
  }
  .researchScreenshot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .researchDialogScreenshot {
    position: absolute;
    top: -55px;
    left: 25%;
    width: 50%;
  }
}

.template {
  display: inline-block;
  vertical-align: top;
  .animatedTemplateCard {
    transition: box-shadow 0.5s linear;
  }
}

#wizard-ai {

  .wizardPurple {
    color: #ad89f7;
  }

  .thinking {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .input, .output {
    overflow-y: auto;
  }

  .blink {
    animation: fade 2000ms infinite;
    -webkit-animation: fade 2000ms infinite;
  }

  .vue-typer {
    font-family: unset !important;
    word-break: break-word;
    &::v-deep .custom.caret {
      background-color: #ad89f7;
      width: 2px;
      &.complete {
        display: inline-block !important;
      }
    }
    &::v-deep .custom.char {
      color: unset !important;
    }
  }
}

@keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.3; }
    to { opacity: 1.0; }
}
@-webkit-keyframes fade {
    from { opacity: 1.0; }
    50% { opacity: 0.3; }
    to { opacity: 1.0; }
}
</style>